import { ref, watch, computed } from '@vue/composition-api'
import store from '@/store'
import i18n from '@/libs/i18n'
// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import moment from 'moment'

export default function useEmployeesList() {
  const toast = useToast()
  const refEmployeeListTable = ref(null)

  // Table Handlers
  const tableColumns = [
    { key: 'employee', label: i18n.t('Name'), sortable: true },
    { key: 'emailadresse', label: i18n.t('Email Address'), sortable: true },
    { key: 'userrole',label: i18n.t('User Role'), sortable: true },
    { key: 'updated_at', label: i18n.t("Updated At"), sortable: true, formatter: val => moment(String(val)).format('DD.MM.YYYY hh:mm:ss') },
    { key: 'actions', label: i18n.t("Actions") },
  ]

  const perPage = ref(10)
  const totalEmployees = ref(0)
  const currentPage = ref(1)
  const perPageOptions = [10, 25, 50, 100]
  const searchQuery = ref('')
  const sortBy = ref('id')
  const isSortDirDesc = ref(true)

  const dataMeta = computed(() => {
    const localItemsCount = refEmployeeListTable.value ? refEmployeeListTable.value.localItems.length : 0
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalEmployees.value,
    }
  })

  const refetchData = () => {
    refEmployeeListTable.value.refresh()
  }

  watch([currentPage, perPage, searchQuery], () => {
    refetchData()
  })

  const fetchEmployees = (ctx, callback) => {
    store
      .dispatch('app-employee/fetchEmployees', {
        search_terms: searchQuery.value,
        size: perPage.value,
        page: currentPage.value,
        sortBy: sortBy.value,
        sortDesc: isSortDirDesc.value,
      })
      .then(response => {
        const responseData = response.data

        callback(responseData.data)
        totalEmployees.value = responseData.meta.total
      })
      .catch((err) => {
        toast({
          component: ToastificationContent,
          props: {
            title: 'Error fetching employees list',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  }

  return {
    fetchEmployees,
    tableColumns,
    perPage,
    currentPage,
    totalEmployees,
    dataMeta,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    refEmployeeListTable,
    refetchData,
  }
}